import request from '@/utils/request'

// 发送短信验证码
export function sendCodePhone(phone) {
	return request({
		url: `/user/sendCode`,
		method: 'get',
		params: {
			phone
		}
	})
}

// 注册账户
export function register(form) {
	return request({
		url: `/user/register`,
		method: 'post',
		data: form
	})
}

// 登录
export function login(form) {
	return request({
		url: `/user/login`,
		method: 'post',
		data: form
	})
}

// 获取图形验证码
export function getCode(form) {
	return request({
		url: `/user/getCode`,
		method: 'get',
		params: form
	})
}

// 获取用户信息
export function getUserInfo() {
	return request({
		url: `/user/getUserInfo`,
		method: 'get',
	})
}

// 保存用户信息
export function saveUserInfo( form) {
	return request({
		url: `/user/saveUserInfo`,
		method: 'post',
		data: form
	})
}
