// import JSEncrypt from 'jsencrypt';
import { JSEncrypt } from "encryptlong";
const publicKeyStr =`
-----BEGIN PUBLIC KEY----- 
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDG8aKpQxl6LFlfc3SyszEDw/wJ7ZXOg02b0yOIbQ7YOPKr6f8E35a8SMx5Tviyvc8/cs+dwfoswKH9JEeDOEwL3EzDhh17DUcDbBovSbS8jg8hnqibMA4eLJr53YddCMS2/ebFQqSxw3vKtpkByc0icWTIk2xLbJGtO5CEstC9GwIDAQAB 
-----END PUBLIC KEY-----`

const privateKey = `
-----BEGIN PUBLIC KEY-----
MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAMbxoqlDGXosWV9zdLKzMQPD/Antlc6DTZvTI4htDtg48qvp/wTflrxIzHlO+LK9zz9yz53B+izAof0kR4M4TAvcTMOGHXsNRwNsGi9JtLyODyGeqJswDh4smvndh10IxLb95sVCpLHDe8q2mQHJzSJxZMiTbEtska07kISy0L0bAgMBAAECgYBz12Xg62pdYo+T7fWozICg8bDD0y0k9/3r21DYcrBvJRHhGrrdCpiKAFXX2S0lXzLbfi34bWgQQQbSERr3BIP6aLj+XnaZ5PaIqQjNZJ/36WZgIMXiyGKri7YswvvoEUY0IcGxk/qEdYaKbhBpzZ6nH5FmiJgJkR0fB831zWsESQJBAO/jI5pD8SU8wejovxRLvxQCATyC35c1ZZTEuuQZS3wTmTUgds/iMpifVyaJrADkBtUBewaSBUScnEYj2QpddU0CQQDUTnmgZn5dOEjvVGnEA+kpRD1E0yX/Wtn4vey/iCTSV/O3TpzRd/ThOQqUIFJbSQUVIRVcSxM2GcmDt4MmaKgHAkEAgvDsFR+y+I2Clxy8eFqkUUqK3DnfzEzURvFfxnRcNL0J6uruj6i7z31id+G8JrxRUmG9gqegAoSt9MdKiJLdHQJAbsIZ9bJCzkMk2abCHr6jjyHIjPVS52rwODJtJmbSPuIfhUv/x8+68xydBjXugS0Q97rwt9t6xEijtpGwXANRVQJAXE+ygtt9fWRELEv91pUFFT0VFkI2SytMUPR5pGgcXQw48PWN1d352aVQ7W9wBTs0jcSxfUG8XQ/5/B/GyB3Vnw==
-----END PUBLIC KEY-----`;
export default {
	encodeRSA(word, keyStr) {
		const jsRsa = new JSEncrypt();
		jsRsa.setPublicKey(publicKeyStr);
		return jsRsa.encryptLong(word);
	},
	decodeRSA(word) {
		const jsRsa = new JSEncrypt()
		jsRsa.setPrivateKey(privateKey)
		return jsRsa.decryptLong(word)
	}
}
